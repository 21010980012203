import React from 'react';
import "./footer.css";
import icon1 from "../../img/face.svg";
import icon2 from "../../img/insta.svg";
import icon3 from "../../img/twiteer.svg";
import icon4 from "../../img/pine.svg";
import icon5 from "../../img/tictok.svg";
import icon6 from "../../img/whats.svg";
import icon7 from "../../img/youtube.svg";


const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
            <div className="footer d-flex justify-content-between">
                <div className="social-links d-flex">
                    <img className='me-3' src={icon1} alt="" />
                    <img className='me-3' src={icon2} alt="" />
                    <img className='me-3' src={icon3} alt="" />
                    <img className='me-3' src={icon4} alt="" />
                    <img className='me-3' src={icon5} alt="" />
                    <img className='me-3' src={icon6} alt="" />
                    <img className='me-3' src={icon7} alt="" />
                </div>
                <div className="copyright">
                    <h5>© Start, 2022. All rights reserved.</h5>
                </div>
            </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;
