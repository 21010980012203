import React from 'react';
import "./header.css";
import hero from "../../img/hero.svg";
import Navbar from '../Navbar/Navbar';

const Header = () => {
  return (
    <>
      <header id='main-header'>
        <div className="container-fluid p-0">
        <Navbar/>
            <div className="container p-0">
                <div className="row align-items-center" id='header'>
                    <div className="col-lg-6 p-0 order-lg-1 padright order-2">
                        <div className="content">
                        <h6>WELCOME</h6>
                        <h1>Lorem ipsum dolor sit amet consectetur </h1>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit nemo hic quos, ab, dolor aperiam nobis cum est eos error ipsum, voluptate culpa nesciunt delectus iste?</p>
                        <a href="#" className='btn'>Explore</a>
                        </div>
                    </div>
                    <div className="col-lg-6 order-lg- content-img order-1">
                        <img src={hero} alt="" className='hero-img img-fluid'/>
                    </div>
                </div>
            </div>
        </div>
        
      </header>
    </>
  )
}

export default Header;
