import React from 'react';
import part1 from "../../img/google.svg";
import part2 from "../../img/microsoft.svg";
import part3 from "../../img/airbnb.svg";
import part4 from "../../img/facebook.svg";
import part5 from "../../img/spotify.svg";
import "./partners.css"


const Partners = () => {
  return (
    <>
      <div className="partners">
         <div className="container p-0">
            <div className="content text-center">
                <h6>PARTNERS</h6>
                <h1>Lorem Ipsum Dolor</h1>
                <h5>Lorem ipsum, dolor sit amet consectetur <br/>adipisicing elit.</h5>
            </div>
            <div className="partners-img row justify-content-between">
                <div className="col-lg-2 p-part"><img src={part1} alt="" className='img-fluid'/></div>
                <div className="col-lg-2 p-part"><img src={part2} alt="" className='img-fluid'/></div>
                <div className="col-lg-2 p-part"><img src={part3} alt="" className='img-fluid'/></div>
                <div className="col-lg-2 p-part"><img src={part4} alt="" className='img-fluid'/></div>
                <div className="col-lg-2 p-part"><img src={part5} alt="" className='img-fluid'/></div>
            </div>
            <div className="btn-learn text-center">
                <a href="#">Learn More</a>
            </div>
         </div>
         </div>
    </>
  )
}

export default Partners;
