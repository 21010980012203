import React from 'react';
import "./navbar.css";
import logo from "../../img/logo.svg";
import menuicon from "../../img/list 1.png"

const Navbar = () => {
  return (
    <>
    <nav className='container-fluid'>
    <div className="container">
      <div className="container-fluid p-0">
      
            <div className="navbar-menu d-flex justify-content-between align-items-center">
                <div className="logo d-flex">
                    <img src={logo} alt="" />
                    <a href="#">Start</a>
                </div>
                <div className="menu d-flex align-items-center">
                    <ul className='d-flex m-0'>
                        <li><a href="">Home</a></li>
                        <li><a href="">Portfolio</a></li>
                        <li><a href="">Services</a></li>
                        <li><a href="">Contact</a></li>
                    </ul>
                    <img src={menuicon} alt="" className='menu-icon'/>
                </div>
            </div>
      </div>
      </div>
    </nav>
    </>
  )
}

export default Navbar;
