import team1 from "../../src/img/team1.svg";
import team2 from "../../src/img/team2.svg";
import team3 from "../../src/img/team3.svg";
import team4 from "../../src/img/team4.svg";

export const teamsData = [
    {
        title:"Peg Legge",
        detail:"CEO",
        img: team1
    },
    {
        title:"Richard Guerra",
        detail:"CTO",
        img: team2
    },
    {
        title:"Alexandra Stolz",
        detail:"DESIGNER",
        img: team3
    },
    {
        title:"Janet Bray",
        detail:"DEVELOPER",
        img: team4
    }
]
