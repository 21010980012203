import React from 'react';
import { detailsData } from '../../Data/detailsData';
import "./details.css";
import details1 from "../../img/details1.svg"

const Details = () => {
  return (
    <>
      <div className="details1">
       <div className="container p-0">
                <div className="row align-items-center">
                    <div className="col-lg-6 order-lg-2 order-2">
                        <div className="details-content">
                        <h1>Lorem ipsum dolor sit amet consectetur  </h1>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit nemo hic quos, ab, dolor aperiam nobis cum est eos error ipsum, voluptate culpa nesciunt delectus iste?</p>
                        <a href="#" className='btn'>Learn More</a>
                        </div>
                    </div>
                    <div className="col-lg-6 order-lg-1 order-1">
                        <img src={details1} alt="" className='details-img img-fluid'/>
                    </div>
                </div>
            </div>
      </div>
    </>
  )
}

export default Details;
