import React from 'react';
import Header from './components/Header/Header';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import Partners from './components/Partners/Partners';
import Details from './components/Details/Details';
import Details2 from './components/Details2/Details2';
import Team from './components/Team/Team';
import Footer from './components/Footer/Footer';

const App = () => {
  return (
    <>
      <Header/>
      <Partners/>
      <Details/>
      <Details2/>
      <Team/>
      <Footer/>
    </>
  )
}

export default App;
