import React from 'react';
import "./Team.css";
import team1 from "../../img/team1.svg";
import { teamsData } from '../../Data/teamsData';

const Team = () => {
    return (
        <>
            <div className="team">
                <div className="container p-0">
                    <div className="content text-center">
                        <h6>TEAM</h6>
                        <h1>Our Talents</h1>
                        <h5>Lorem ipsum, dolor sit amet consectetur <br />Suscipit nemo hic quos, ab.</h5>
                    </div>
                    <div className="team-card row gy-4">

                    {teamsData.map((team) => (
                        <div className="col-lg-3 col-md-6">
                            <div className="card1">
                                <div className="card-img text-center"><img src={team.img} alt="" /></div>
                                <div className="card-content text-left">
                                    <h3>{team.title}</h3>
                                    <p>{team.detail}</p>
                                </div>
                            </div></div>
                        
                    ))}
                    </div>
                    <div className="btn-team text-center">
                             <a href="#">View Team</a>
                         </div>
                   



                </div>
            </div>
        </>
    )
}

export default Team;
